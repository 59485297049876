#myLandbot {
  height: 668px;
  background-color: black;
  padding: 10% 2%;
  border-radius: 32px;
  width: 80%;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    width: 92%;
  }
}

.section {
  background: url('../assets/images/bg@2x.png') 70% / contain no-repeat, #fa355a;
}