$duplex-color: #f4375b;

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults

$font-family-base: 'Montserrat', sans-serif;

$nav-link-hover-color: $duplex-color!default;
$link-hover-color: $duplex-color!default;
