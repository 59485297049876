.section-1 {
  background: url('../assets/images/cabecera.webp') 70% center / contain no-repeat, #fa355a;
}

.section-3 {
  .banner {
    max-width: 100%;
  }
}

.section-5 {
  background: url(data-url:../assets/images/fondo@3x.webp) center center / cover no-repeat, #fa355a;
}

.grecaptcha-badge {
  visibility: hidden;
}

.text-duplex {
  color: $duplex-color!important;
}

.text-gray {
  color: #797979!important;
}

.btn-duplex {
  background: $duplex-color!important;
}

.border-duplex {
  border-color: $duplex-color!important;
}

.alert-success {
  --bs-alert-color: #000000!important;
  --bs-alert-bg: #b9ffdf!important;
  --bs-alert-border-color: #ffffff!important;
}

.form-check-input:checked, .form-check-input:checked[type=checkbox] {
  background-color: $duplex-color!important;
}

input[type="text"] {
  box-shadow: none!important;
}

h1, .h1 {
  font-size: toRem(52);
}

h2, .h2 {
  font-size: toRem(42);
}

h3, .h3 {
  font-size: toRem(30);
}

h4, .h4 {
  font-size: toRem(25);
}

h5, .h5 {
  font-size: toRem(20);
}

.ticked-list {
  list-style: none;
  padding: 0;

  li {
    padding-left: 3.4em;
    position: relative;
    margin-bottom: 1em;

    &:before {
      position: absolute;
      content: '';
      background: url('../assets/images/duplex-tick.svg') no-repeat;
      left: 0;
      top: 8px;
      height: 32px;
      width: 36px;
    }
  }
}

.icon {
  position: absolute;
  bottom: 20%;
  right: -28%;
}

.state-active {
  span {
    display: inline-block!important;
  }
}

//section:hover .whatsapp {
//  &:before {
//    display: block;
//    width: 105%;
//    margin-left: -2.5%;
//    transition: all .3s ease-in-out;
//  }
//}

.nav-link {
  color: black!important;
}

.nav-link:hover, .active {
  color: $duplex-color!important;
}

@include media-breakpoint-down(md) {
  h1, .h1 {
    font-size: toRem(52 / 1.7);
  }

  h2, .h2 {
    font-size: toRem(42 / 1.7);
  }

  h3, .h3 {
    font-size: toRem(30 / 1.7);
  }

  h4, .h4 {
    font-size: toRem(25 / 1.7);
  }

  h5, .h5 {
    font-size: toRem(20 / 1.1);
  }

  .mb-sm-4 {
    margin-bottom: .75em;
  }

  .images-grid {
    img {
      height: 87px;
    }
  }

  .icon {
    bottom: 15%;
    right: -35%;

    svg {
      height: 40px;
    }
  }

  .border-sm-none {
    border: none!important;
  }

  .section-1 {
    height: 595px;
    background-position: 100% 100%;
    background-size: 150%;
  }
}